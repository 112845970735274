import { EsapActions, EsapParams } from '@app/core/configuration/esap';
import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { ApiClient } from '@app/core/net/http/api/api-client';

/**
 * Модель запроса для вызова API ClientAuth.
 */
export class ClientAuthReq extends ApiClient {

	/**
	 * Конструктор запроса.
	 *
	 * @param {AppStoreService} appStoreService Централизованое хранилище данных.
	 */
	constructor(
		readonly appStoreService: AppStoreService
	) {
		super(appStoreService, appStoreService.Settings.getEsapActionUrl(LotteryGameCode.Undefined, EsapActions.ClientAuth));

		this.params.append(EsapParams.UID, appStoreService.Settings.termUid);
		const objClientInfo = JSON.parse(appStoreService.Settings.clientInfo);
		objClientInfo.vers = /\d+\.\d+\.\d+/.test(appStoreService.Settings.hardwareInfo.softwareVersion) ?
			appStoreService.Settings.hardwareInfo.softwareVersion : '0.1.1';
		this.params.append(EsapParams.CLIENT_INFO, JSON.stringify(objClientInfo));
	}

}
