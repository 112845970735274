import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from '@app/env/environment';
import { URL_AUTH } from '@app/util/route-utils';
import { Exit } from '@app/util/utils';
import { validateResponseSync } from '@app/util/validator';
import { AuthService } from '@app/core/services/auth.service';
import { CsConfig } from '@app/core/configuration/cs';
import { DialogContainerService } from '@app/core/dialog/services/dialog-container.service';
import { DialogError, ErrorCode } from '@app/core/error/dialog';
import { IError, NetError } from '@app/core/error/types';
import { HttpService } from '@app/core/net/http/services/http.service';
import { ApplicationAppId, LanguageList } from '@app/core/services/store/settings';
import { TransactionService } from '@app/core/services/transaction/transaction.service';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { LogService } from '@app/core/net/ws/services/log/log.service';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { StorageService } from '@app/core/net/ws/services/storage/storage.service';
import { PrintService } from '@app/core/net/ws/services/print/print.service';
import { StorageGetResp } from '@app/core/net/ws/api/models/storage/storage-get';
import { SERVICE_CHECK_INTERVAL } from '@app/core/net/ws/ws-models';
import { StorageKeys } from '@app/core/net/ws/api/models/storage/storage-models';
import { InitGuard } from '@app/core/guards/init.guard';
import { ChangeLogService } from '@app/sys-info/services/change-log.service';

export const CLIENT_INFO = {
	self_test: 1,
	os_check_sum: {
		sum: '',
		ts: ''
	},
	app_check_sum: {
		sum: '',
		ts: ''
	},
	hw: '',
	gprs_location: [],
	vers: '0.1.1',
	date_off: '',
	sn: '',
	route: '',
	com_dev: ''
};

/**
 * Сервис инициализации процедуры запуска терминала.
 */
@Injectable()
export class InitService {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Состояние шторки инициализации терминала.
	 * Если true, то поверх всех окон будет показан прелоадер,
	 * означающий что идет инициализация терминала.
	 */
	readonly curtainVisibility$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Подписка на мониторинг состояния принтера.
	 * @private
	 */
	private subscription: Subscription;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {InitGuard} initGuard Контроль доступа к модулям (страницам) приложения на основе статусе инициализации
	 * @param {AppStoreService} appStoreService Сервис хранилища данных приложения
	 * @param {StorageService} storageService Сервис хранилища данных в localStorage
	 * @param {LogService} logService Сервис логирования
	 * @param {PrintService} printService Сервис печати
	 * @param {HttpService} httpService Сервис http-запросов
	 * @param {TransactionService} transactionService Сервис транзакций
	 * @param {DialogContainerService} dialogInfoService Сервис диалоговых окон
	 * @param {TranslateService} translate Сервис локализации
	 * @param {Router} router Сервис маршрутизации
	 * @param {AuthService} authService Сервис авторизации
	 * @param {ChangeLogService} changeLogService Сервис журнала изменений
	 */
	constructor(
		private readonly initGuard: InitGuard,
		private readonly appStoreService: AppStoreService,
		private readonly storageService: StorageService,
		private readonly logService: LogService,
		private readonly printService: PrintService,
		private readonly httpService: HttpService,
		private readonly transactionService: TransactionService,
		private readonly dialogInfoService: DialogContainerService,
		private readonly translate: TranslateService,
		private readonly router: Router,
		private readonly authService: AuthService,
		private readonly changeLogService: ChangeLogService
	) {}

	/**
	 * Начало процедуры запуска терминала.
	 * Инициализация сервиса локализации терминала.
	 */
	init(): void {
		Logger.Log.i('InitService', `Starting terminal initialization`)
			.console();

		// Сравниваем версии и если они отличаются, то очищаем localStorage
		// НО новую версию ТУТ не заносим. Заносим позже
		const verArr: StorageGetResp = this.storageService.getSync(ApplicationAppId, [StorageKeys.LatestRunningVersion]);
		if (!verArr.data[0] || (verArr.data[0].value !== environment.version)) {
			localStorage.removeItem(ApplicationAppId);
		}

		const hwInfo = this.storageService.hardwareInfo(ApplicationAppId);
		this.appStoreService.Settings.parseHardwareInfoResponse(hwInfo);
		this.appStoreService.Settings.clientInfo = JSON.stringify(CLIENT_INFO);

		this.initLocalization();
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Инициализация сервиса локализации терминала.
	 */
	private initLocalization(): void {
		this.translate.addLangs(LanguageList.map(v => v.value));

		if (!this.appStoreService.Settings.lang) {
			this.appStoreService.Settings.lang = 'ua';
		}
		this.translate.use(this.appStoreService.Settings.lang);

		Logger.Log.i('InitService', `current lang: ${this.translate.currentLang}`)
			.console();

		this.dialogInfoService.hideAll();

		/**
		 * Получение информации о конфигурации Print-сервиса.
		 */
		if (environment.printerUrl) {
			this.appStoreService.Settings.printServiceUrl = environment.printerUrl;
			this.initPrintService();
		}

		this.getAppInitSetting();
	}

	/**
	 * Инициализация настроек приложения.
	 * @param err Передаваемый объект ошибки
	 * @private
	 */
	private initAppSettings(err?: Event): void {
		if (err) {
			Logger.Log.e('InitService', `init print monitor got: error: ${err.type}`)
				.console();
		} else {
			Logger.Log.i('InitService', 'printer monitor got: ready')
				.console();
		}
		this.showCurtain(false);
		this.dialogInfoService.hideAll();
		this.subscription.unsubscribe();
		this.getAppInitSetting();
	}

	/**
	 * Инициализация и подключение к Print-сервису.
	 */
	private initPrintService(): void {
		this.showCurtain(true);
		Logger.Log.i('InitService', 'init print service')
			.console();

		this.subscription = this.printService.stateMonitor({
			ready: this.initAppSettings.bind(this),
			error: err => this.initAppSettings.bind(this, err)
		});

		// инициализация сервиса печати (для прода всегда по настройкам)
		this.printService.init(this.appStoreService.Settings.printServiceUrl, SERVICE_CHECK_INTERVAL);
	}

	/**
	 * Получение и обработка начальных значений параметров терминала из Storage-сервиса.
	 */
	private getAppInitSetting(): void {
		// если авторизация уже была, переинициализировать терминал ненужно
		if (this.appStoreService.isLoggedIn$$.value) {
			return;
		}

		this.showCurtain(true);
		const appInitKeys = this.appStoreService.Settings.getKeysForAppInit();

		Logger.Log.i('InitService', `getAppInitSetting, keys: %s`, appInitKeys)
			.console();

		this.storageService.get(ApplicationAppId, appInitKeys, undefined, 10000, 3)
			.then(response => {
				Logger.Log.i('InitService', 'getAppInitSetting got response: %s', response)
					.console();

				if (!!this.subscription) {
					this.subscription.unsubscribe();
				}
				this.showCurtain(false);
				const storageData = response as StorageGetResp;

				// для девелоперской среды данные подставятся из файлов environment.xxx.ts
				if (storageData.data) {
					const insertions = this.appStoreService.Settings.populateInitialSetting(storageData.data);
					if (insertions && insertions.length > 0) {
						this.showCurtain(true);
						this.storageService.put(ApplicationAppId, insertions, 5000, 3)
							.then(() => this.initLocalization())
							.catch((error: IError) => {
								Logger.Log.e('InitService', `can't put data %s to storage, error: ${error.message}`, insertions)
									.console();

								this.showCurtain(false);
								this.dialogInfoService.showOneButtonError(
									new DialogError(ErrorCode.TermSaveParam, 'dialog.term_init_error'), {
										click: Exit,
										text: 'dialog.dialog_button_exit'
									}
								);
							});
					} else {
						this.switchLocalization();
					}
				} else {
					Logger.Log.e('InitService', 'getAppInitSetting error: data is absent')
						.console();

					this.dialogInfoService.showOneButtonError(
						new DialogError(ErrorCode.TermAbsentParam, 'dialog.term_param_absent_error'), {
							click: Exit,
							text: 'dialog.dialog_button_exit'
						}
					);
				}
			})
			.catch((error: IError) => {
				Logger.Log.e('InitService', `getAppInitSetting got error: ${error.message}`)
					.console();

				this.showCurtain(false);
				this.dialogInfoService.showOneButtonError(
					new DialogError(ErrorCode.TermInit, 'dialog.term_init_error'),
					{
						click: Exit,
						text: 'dialog.dialog_button_exit'
					}
				);
			});
	}

	/**
	 * Переключение локализации терминала на значение установленное в параметрах терминала.
	 */
	private switchLocalization(): void {
		this.translate.use(this.appStoreService.Settings.defaultLang);
		this.getEsapConfig();
	}

	/**
	 * Получение и обработка конфигурации терминала из ЦС.
	 */
	private getEsapConfig(): void {
		Logger.Log.i('InitService', `getEsapConfig -> get config by url: ${this.appStoreService.Settings.csEnvironmentUrl}`)
			.console();

		this.showCurtain(true);
		this.httpService
			.sendGet(this.appStoreService.Settings.csEnvironmentUrl, undefined)
			.then(data => {
				this.showCurtain(false);

				let obj;
				try {
					obj = JSON.parse(data);
				} catch (err) {
					Logger.Log.e('InitService', `getEsapConfig -> can't parse JSON config, error: ${err.message}`)
						.console();

					throw err;
				}

				// obj.lotteries[0].codes = []; // TODO: для тестов ЭМЛ
				validateResponseSync<CsConfig>(CsConfig, obj, {
					onSucceed: response => {
						Logger.Log.i('InitService', 'getEsapConfig -> config VALIDATED OK, got response: %s', response)
							.console();

						const error = this.appStoreService.Settings.populateEsapActionsMapping(response);
						if (error) {
							Logger.Log.e('InitService', `getEsapConfig -> config population error: %s`, error)
								.console();

							this.dialogInfoService.showOneButtonError(
								new DialogError(ErrorCode.ConfigCsParam, 'dialog.config_cs_error'),
								{
									click: Exit,
									text: 'dialog.dialog_button_exit'
								}
							);
						} else {
							this.restoreTransactionState();
						}
					},
					onFailed: (error: IError) => {
						Logger.Log.e('InitService', `getEsapActionsMapping validate error: ${error.message}`)
							.console();
						throw error;
					}
				});
			})
			.catch((error: IError) => {
				Logger.Log.e('InitService', `getEsapActionsMapping got error: ${error.message}`)
					.console();

				this.showCurtain(false);
				if (error instanceof NetError) {
					this.dialogInfoService.showOneButtonError(
						error,
						{
							click: () => this.getEsapConfig(),
							text: 'dialog.dialog_button_repeat'
						}
					);
				} else {
					this.dialogInfoService.showOneButtonError(
						new DialogError(ErrorCode.ConfigCs, 'dialog.config_cs_error'),
						{
							click: Exit,
							text: 'dialog.dialog_button_exit'
						}
					);
				}
			});
	}

	/**
	 * Проверка на наличие незавершенной транзакции.
	 */
	private restoreTransactionState(): void {
		// TODO: restore transaction
		this.showCurtain(false);
		this.transactionService.checkLastTransactionState()
			.subscribe(() => {
				Logger.Log.i('InitService', 'transaction restored SUCCESSFULLY')
					.console();

				this.readChangelogData();
			}, error => {
				Logger.Log.e('InitService', `transaction restore ERROR: ${error.message}`)
					.console();
				this.dialogInfoService.showOneButtonError(new DialogError(error.code, 'dialog.loading_integrity_error'),
					{
						click: Exit,
						text: 'dialog.dialog_button_exit'
					}
				);
			});
	}

	/**
	 * Запускает процедуру чтения текущего чейнджлога.
	 */
	private readChangelogData(): void {
		Logger.Log.i('InitService', 'start reading changelog data...')
			.console();

		this.changeLogService.requestChangelog();

		this.routeAuth();
	}

	/**
	 * Переход в окно логина.
	 */
	private routeAuth(): void {
		this.dialogInfoService.hideAll();
		this.initGuard.isInitialized = true;
		this.showCurtain(false);

		this.router.navigate([URL_AUTH])
			.catch(err => {
				Logger.Log.e('InitService', `can't navigate to AUTH page: %s`, err)
					.console();
			});
	}

	/**
	 * Изменить состояние шторки при инициализации терминала.
	 *
	 * @param {boolean} value Показать или скрыть шторку.
	 */
	private showCurtain(value: boolean): void {
		if (value && this.appStoreService.isLoggedIn$$.value) {
			return;
		}

		this.curtainVisibility$$.next(value);
	}

}
