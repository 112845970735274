import { EsapActions, EsapParams } from '@app/core/configuration/esap';
import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { ApiClient } from '@app/core/net/http/api/api-client';
import { AccessPosListItemGS, Auth2Type, BOOperations, IResponse } from '@app/core/net/http/api/types';
import { TerminalRoles } from '@app/core/services/store/operator';

// -----------------------------
//  Request
// -----------------------------

/**
 * Модель запроса для вызова API BOGetSIDReq.
 */
export class BOGetSIDReq extends ApiClient {

	/**
	 * Конструктор запроса.
	 *
	 * @param {AppStoreService} appStoreService Централизованое хранилище данных.
	 * @param {string} login логин
	 * @param {string} pass пароль
	 */
	constructor(
		appStoreService: AppStoreService,
		login: string,
		pass: string
	) {
		super(appStoreService, appStoreService.Settings.getEsapActionUrl(LotteryGameCode.Undefined, EsapActions.BOGetSID));

		this.params.append(EsapParams.LOGIN, login);
		this.params.append(EsapParams.PASSWD, pass);
	}

}

// -----------------------------
//  Response
// -----------------------------

/**
 * Модель ответа на запрос API BOAuthenticate
 */
export interface BOGetSIDResp extends IResponse {

	/**
	 * Идентификатор пользователя
	 */
	user_id: string;

	/**
	 * Идентификатор созданной сессии
	 */
	sid: string;

	/**
	 * Указывает на способ и необходимость второй фазы аутентификации (для двухфакторной аутентификации).
	 * Возможные значения:
	 * sms - указывает на то, что система требует пройти аутентификацию через указание полученного SMS-кода.
	 * Сервис на телефон пользователя отправит 4-значный SMS-код для аутентификации'
	 */
	auth2?: Auth2Type;

	/**
	 * Идентификатор клиента
	 */
	client_id?: string;

	/**
	 * Массив терминалов и точек продаж
	 */
	pos_list?: Array<AccessPosListItemGS>;

}
