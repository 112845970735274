import { Injectable } from '@angular/core';
import { RoutedTransport, RoutedTransportType } from '@app/core/net/ws/routed-transport';
import { ApplicationAppId, AppType, Settings } from '@app/core/services/store/settings';
import { IResponse, KeyValue } from '@app/core/net/ws/api/types';
import { StoragePutReq } from '@app/core/net/ws/api/models/storage/storage-put';
import { StorageGetReq, StorageGetResp } from '@app/core/net/ws/api/models/storage/storage-get';
import { StorageDelReq } from '@app/core/net/ws/api/models/storage/storage-del';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import {StorageHardwareInfoResp} from "@app/core/net/ws/api/models/storage/storage-hardware-info";
import {environment} from "@app/env/environment";

/**
 * Успешный ответ.
 */
const SUCCESS_RESP = {requestId: '0', errorCode: 0, errorDesc: ''};

/**
 * Сервис реализующий функционал по взаимодействию с локальным хранилищем в браузере.
 */
@Injectable({
	providedIn: 'root'
})
export class StorageService extends RoutedTransport {

	/**
	 * Конструктор сервиса.
	 */
	constructor(private readonly appStoreService: AppStoreService) {
		super(RoutedTransportType.Storage);
	}

	/**
	 * Отправка запроса на запись данных в Storage-сервис.
	 *
	 * @param {string} appId идентификатор приложения
	 * @param {KeyValue[]} data данные для записи
	 * @param {number} timeout таймаут ожидания ответа
	 * @param {number} retry кол-во повторов до генерации ошибки
	 * @returns {Promise<IResponse>}
	 */
	put(appId: string, data: Array<KeyValue>, timeout?: number, retry?: number): Promise<IResponse> {
		return new Promise<IResponse>((resolve, reject) => {
			if (this.appStoreService.Settings.appType === AppType.ALTTerminal) {
				const request = new StoragePutReq(appId);
				request.data = data;
				this.sendApiRequest(request, {onResult: resolve, onError: reject}, timeout, retry);
			} else {
				this.putSync(appId, data);
				resolve(SUCCESS_RESP);
			}
		});
	}

	/**
	 * Синхронная запись данных в Storage-сервис.
	 *
	 * @param {string} appId идентификатор приложения
	 * @param {KeyValue[]} data данные для записи
	 */
	putSync(appId: string, data: Array<KeyValue>): void {
		const fullStorage = this.getLocalStorage();
		const keysMap = this.getKeysMap(fullStorage.data);
		for (const item of data) {
			const keyIndex = keysMap.get(item.key);
			if (Number.isInteger(keyIndex)) {
				fullStorage.data[keyIndex] = item;
			} else {
				fullStorage.data.push(item);
				keysMap.set(item.key, fullStorage.data.length - 1);
			}
		}
		localStorage.setItem(appId, JSON.stringify(fullStorage));
	}

	/**
	 * Отправка запроса на чтение данных из Storage-сервиса.
	 *
	 * @param {string} appId идентификатор приложения
	 * @param {string[]} data данные для чтения
	 * @param {string} owner идентификатор владельца данных
	 * @param {number} timeout таймаут ожидания ответа
	 * @param {number} retry кол-во повторов до генерации ошибки
	 * @returns {Promise<IResponse>}
	 */
	get(appId: string, data: Array<string>, owner?: string, timeout?: number, retry?: number): Promise<IResponse> {
		return new Promise<IResponse>((resolve, reject) => {
			if (this.appStoreService.Settings.appType === AppType.ALTTerminal) {
				const request = new StorageGetReq(appId, owner);
				request.data = data;
				this.sendApiRequest(request, {onResult: resolve, onError: reject}, timeout, retry);
			} else {
				resolve(this.getSync(appId, data));
			}
		});
	}

	/**
	 * Синхронное чтение данных из Storage-сервиса.
	 *
	 * @param {string} appId идентификатор приложения
	 * @param {string[]} data данные для чтения
	 * @returns {StorageGetResp}
	 */
	getSync(appId: string, data: Array<string>): StorageGetResp {
		const fullStorage = this.getLocalStorage();
		const keysMap = this.getKeysMap(fullStorage.data);
		const result = [];
		for (const key of data) {
			const keyIndex = keysMap.get(key);
			if (Number.isInteger(keyIndex)) {
				result.push(fullStorage.data[keyIndex]);
			}
		}

		return {data: result, errorCode: 0, errorDesc: '', requestId: ''};
	}

	/**
	 * Отправка запроса на удаление данных из Storage-сервиса.
	 *
	 * @param {string} appId идентификатор приложения
	 * @param {string[]} data данные для удаления
	 * @param {number} timeout таймаут ожидания ответа
	 * @param {number} retry кол-во повторов до генерации ошибки
	 * @returns {Promise<IResponse>}
	 */
	del(appId: string, data: Array<string>, timeout?: number, retry?: number): Promise<IResponse> {
		return new Promise<IResponse>((resolve, reject) => {
			if (this.appStoreService.Settings.appType === AppType.ALTTerminal) {
				const request = new StorageDelReq(appId);
				request.data = data;
				this.sendApiRequest(request, {onResult: resolve, onError: reject}, timeout, retry);
			} else {
				this.delSync(appId, data);
				resolve(SUCCESS_RESP);
			}
		});
	}

	/**
	 * Синхронное удаление данных из Storage-сервиса.
	 *
	 * @param {string} appId идентификатор приложения
	 * @param {string[]} data данные для удаления
	 */
	delSync(appId: string, data: Array<string>): void {
		const fullStorage = this.getLocalStorage();
		const keysMap = this.getKeysMap(fullStorage.data);
		for (const key of data) {
			const keyIndex = keysMap.get(key);
			if (Number.isInteger(keyIndex)) {
				fullStorage.data[keyIndex] = null;
			}
		}
		fullStorage.data = fullStorage.data.filter(elem => !!elem);
		localStorage.setItem(appId, JSON.stringify(fullStorage));
	}

	/**
	 * Получение данных об аппаратной конфигурации терминала.
	 *
	 * @param {string} appId идентификатор приложения
	 * @param {number} timeout таймаут ожидания ответа
	 * @param {number} retry кол-во повторов до генерации ошибки
	 * @returns {StorageHardwareInfoResp}
	 */
	hardwareInfo(appId: string, timeout?: number, retry?: number): StorageHardwareInfoResp {
		return {
			errorDesc: '',
			errorCode: 0,
			requestId: '0',
			data: [{
				androidId: 'FORESEE_64GB_SSD_I37392J013500',
				networkInterface: [{key: 'eth0', value: '68:ed:a4:22:ee:9d'}],
				softwareVersion: environment.version,
				deviceId: 'IRZ',
				simSerialNumber: '0'
			}]
		};
	}

	/**
	 * Получить полный объект хранилища из localStorage
	 */
	private getLocalStorage(): {data: Array<KeyValue>} {
		let fullStorage = JSON.parse(localStorage.getItem(ApplicationAppId));
		if (!fullStorage) {
			fullStorage = {};
			localStorage.setItem(ApplicationAppId, JSON.stringify(fullStorage));
		}
		if (!fullStorage.data || !fullStorage.data.length) {
			fullStorage.data = [];
			localStorage.setItem(ApplicationAppId, JSON.stringify(fullStorage));
		}

		return fullStorage;
	}

	/**
	 * Получить хешмап ключ => индекс из хранилища
	 */
	private getKeysMap(data: Array<KeyValue>): Map<string, number> {
		const keysMap = new Map<string, number>();
		data.forEach((elem: KeyValue, i: number) => {
			keysMap.set(elem.key, i);
		});

		return keysMap;
	}
}
